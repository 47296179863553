<script setup></script>
<template>
  <main class="min-h-[100vh] w-full grow bg-gray-200 dark:bg-background">
    <div class="flex justify-between items-center text-white bg-gray-700 p-4 shadow-lg print:hidden">
      <p>{{ useUser().getUser.email }}</p>
      <Button @click="useLogout" v-if="useUser().getUser.id">Logout</Button>
    </div>
    <div class="flex flex-col gap-6 p-2 lg:py-10 mx-auto max-w-7xl">
      <slot />
    </div>
  </main>
</template>
